import axios from "axios";
import md5 from "md5";
export const getApiUrl = () => {
  //this placeholder get replaced with run time API domain URL in deployment process w.r.t environment
  return "REPLACE_TOPNAV_API_ENDPOINT";
};
export interface authResponse {
  getUser();
  getToken();
  logout();
}
export interface errorResponse {
  getErrorComponent(errorMessage, resetFn);
}

export const frontendAuth = async (): Promise<authResponse> => {
  try {
    const authUtil = await System.import("@gentrack/frontend-auth");
    const getter: authResponse = {
      getToken: authUtil.getTokenSilently,
      getUser: authUtil.getUser,
      logout: authUtil.logout,
    };
    return getter;
  } catch (error) {
    throw new Error(`Error fetching auth detail: ${error.message}`);
  }
};

export const frontendErrorUtil = async (): Promise<errorResponse> => {
  try {
    const errorUtil = await System.import("@gentrack/frontend-components");
    const getter: errorResponse = {
      getErrorComponent: errorUtil.frontendAlert,
    };
    return getter;
  } catch (error) {
    throw new Error(`Error fetching error compo detail: ${error.message}`);
  }
};

export type MenuConfig = {
  url: string;
  name: string;
  pathRoute: string;
  description?: string;
  iconUrl?: string;
};
export interface TopNavConfigResponse {
  logoUrl?: string;
  production?: boolean;
  orgId?: string;
  assetPath?: string;
  helpUrl?: string;
  settingsUrl?: string;
  tenantName?: string;
  menuConfig: MenuConfig[];
}

export const getTenantDetail = async (accessToken): Promise<TopNavConfigResponse> => {
  const { data: tenantData } = await axios.get(`${getApiUrl()}/top-nav/config`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  });
  return tenantData;
};
export const postLog = async (errorDetail) => {
  const frontendAuthentication = await frontendAuth();
  const accessToken = await frontendAuthentication.getToken();
  axios.post(
    `${getApiUrl()}/frontend/log`,
    { errorDetail },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );
};

export const getGravatarImage = (email: string, baseURI: string) => {
  const userHashCode = md5(email);
  const defaultUserIconURI = encodeURI(`${baseURI}assets/user-settings/default_user_icon.png`);
  //when an email address has no matching Gravatar image, supply the URL of own default image to an image request in the d= or default= parameter
  return `${process.env.GRAVATAR_IMAGE_REQUEST}/${userHashCode}?d=${defaultUserIconURI}`;
};
