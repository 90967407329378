/* eslint-disable jsdoc/no-undefined-types */
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { Link, BrowserRouter as Router } from "react-router-dom";
import "./launchpad.css";
import Button from "@mui/material/Button";
import React, { useEffect, useState } from "react";
import { TopNavConfigResponse, frontendAuth, getTenantDetail } from "../../../common/getConfig";

/**
 *
 * @returns {JSX.Element} JSX
 */

const Launchpad = () => {
  const [menuList, setMenuList] = useState([]);
  const [user, setUser] = useState("");
  const [baseURI, setBaseURI] = useState(null);
  const fetchData = async () => {
    try {
      const frontendAuthentication = await frontendAuth();
      const user = await frontendAuthentication.getUser();
      console.log(user);
      setUser(user.name);

      const accessToken = await frontendAuthentication.getToken();
      const tenant: TopNavConfigResponse = await getTenantDetail(accessToken);

      setBaseURI(tenant.assetPath);

      setMenuList(tenant.menuConfig);
    } catch (err) {
      console.error(err.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <>
      <div className="container" style={{ backgroundImage: `url(${baseURI + `assets/landing.svg`})` }}>
        <Grid container direction="row">
          <Grid item xs={4} lg={9} xl={9} sm={7} md={7}>
            <Typography variant="h4">Welcome Back, {user} !</Typography>
          </Grid>
          <Grid item xs={6} lg={1} xl={1} sm={1}>
            <img src={`${baseURI}assets/gentrack.svg`} />
          </Grid>
        </Grid>
        <Grid
          container
          className="modules"
          xs={12}
          lg={4}
          xl={menuList.length + 1}
          sm={5}
          spacing={2}
          md={5}
          columnGap={2}
          rowGap={1}
        >
          <Grid item xs={12} lg={12} xl={12} sm={12}>
            <Typography variant="body1">G2 Modules</Typography>
          </Grid>
          {menuList &&
            menuList[0] &&
            menuList.map((menu, index) => (
              <>
                <Grid item xs={3} lg={3} xl={4} sm={3} md={3}>
                  <Router>
                    <Link key={index} to={menu.pathRoute}>
                      <Button style={{ backgroundColor: "#01243b" }}>
                        <img src={menu.iconUrl} style={{ width: "100%", height: "auto" }} />
                      </Button>
                    </Link>
                    <Typography variant="subtitle1">{menu.name}</Typography>
                  </Router>
                </Grid>
              </>
            ))}
        </Grid>
      </div>
    </>
  );
};
export default Launchpad;
