import parse from "html-react-parser";
import { useEffect, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { frontendErrorUtil, postLog } from "../common/getConfig";
import Lauchpad from "./src/components/launchpad";
import { ThemeProvider } from "@mui/material/styles";
import ThemeToggler from "../src/components/theme-toggler";
import React from "react";
/**
 * App to handle error fall back
 * @returns {object} a react object
 */
export default function App() {
  const [errorDom, setErrorDom] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [resetErrorFn, setResetErrorFn] = useState({});
  useEffect(() => {
    fetchErrorHandlerUI();
  }, [errorMessage]);

  /**
   *
   * @param {any} error .
   * @param {any} error.error .
   * @param {any} error.resetErrorBoundary .
   * @returns {object} a html dom with the parsed error
   */
  function fallbackRender({ error, resetErrorBoundary }) {
    setErrorMessage(error);
    setResetErrorFn(() => resetErrorBoundary);
    return <div>{parse(errorDom)}</div>;
  }
  const fetchErrorHandlerUI = async () => {
    const frontendErrorLib = await frontendErrorUtil();
    const errorComponent = await frontendErrorLib.getErrorComponent(errorMessage, {});
    setErrorDom(errorComponent);
  };
  const [currentTheme, setCurrentTheme] = useState("dark");

  const selectTheme = (mode) => {
    setCurrentTheme(() => mode);
  };

  return (
    <ThemeProvider theme={ThemeToggler.selectTheme(currentTheme)}>
      <ErrorBoundary
        fallbackRender={fallbackRender}
        onError={async (error: Error) => {
          await postLog(error.message);
        }}
      >
        <Lauchpad />
      </ErrorBoundary>
    </ThemeProvider>
  );
}
