import React from "react";
import ReactDOM from "react-dom";
import singleSpaReact from "single-spa-react";
import App from "../App";

const reactLifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: App,
});
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const mount = async (props: any) => {
  await reactLifecycles.mount(props);
};
export const { bootstrap, unmount } = reactLifecycles;
