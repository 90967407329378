// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  color: #fff;
  font-family: Roboto;
  padding: 10vh;
  background-size: cover; 
  background-position: center; 
  position: fixed;
  top: 60px; /* Height of the app bar */
  left: 0;
  width: 100%;
  height: calc(100vh - 60px); 
}
.gentrackLogo {
  background-size: cover;
  background-position: center; 
  width: 260px;
  height: 100px; 

}
.modules{
  padding: 20px;
  background-color: #273B49;
  border-radius: 10px;
 
}`, "",{"version":3,"sources":["webpack://./src/components/launchpad.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,mBAAmB;EACnB,aAAa;EACb,sBAAsB;EACtB,2BAA2B;EAC3B,eAAe;EACf,SAAS,EAAE,0BAA0B;EACrC,OAAO;EACP,WAAW;EACX,0BAA0B;AAC5B;AACA;EACE,sBAAsB;EACtB,2BAA2B;EAC3B,YAAY;EACZ,aAAa;;AAEf;AACA;EACE,aAAa;EACb,yBAAyB;EACzB,mBAAmB;;AAErB","sourcesContent":[".container {\n  color: #fff;\n  font-family: Roboto;\n  padding: 10vh;\n  background-size: cover; \n  background-position: center; \n  position: fixed;\n  top: 60px; /* Height of the app bar */\n  left: 0;\n  width: 100%;\n  height: calc(100vh - 60px); \n}\n.gentrackLogo {\n  background-size: cover;\n  background-position: center; \n  width: 260px;\n  height: 100px; \n\n}\n.modules{\n  padding: 20px;\n  background-color: #273B49;\n  border-radius: 10px;\n \n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
