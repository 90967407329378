import { createTheme } from "@mui/material/styles";

const ThemeToggler = {
  lightTheme: createTheme({
    palette: {
      mode: "light",
      primary: {
        light: "#7986cb",
        main: "#3f51b5",
        dark: "#303f9f",
        contrastText: "#fff",
      },
      secondary: {
        light: "#ff4081",
        main: "#f50057",
        dark: "#c51162",
        contrastText: "#fff",
      },
      // Add more palette colors as needed
    },
    // Add more theme configurations
  }),

  darkTheme: createTheme({
    palette: {
      mode: "dark",
      primary: {
        light: "#7986cb",
        main: "#3f51b5",
        dark: "#303f9f",
        contrastText: "#fff",
      },
      secondary: {
        light: "#ff4081",
        main: "#f50057",
        dark: "#c51162",
        contrastText: "#fff",
      },
      // Add more palette colors as needed
    },
    // Add more theme configurations
  }),

  selectTheme: (mode) => {
    return mode === "light" ? ThemeToggler.lightTheme : ThemeToggler.darkTheme;
  },
};

export default ThemeToggler;
